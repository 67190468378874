@import "variables";
@import "tools";
@import "reset";
@import "general";
@import "objects";


/***
 *     ######   ######## ##    ## ######## ########     ###    ##
 *    ##    ##  ##       ###   ## ##       ##     ##   ## ##   ##
 *    ##        ##       ####  ## ##       ##     ##  ##   ##  ##
 *    ##   #### ######   ## ## ## ######   ########  ##     ## ##
 *    ##    ##  ##       ##  #### ##       ##   ##   ######### ##
 *    ##    ##  ##       ##   ### ##       ##    ##  ##     ## ##
 *     ######   ######## ##    ## ######## ##     ## ##     ## ########
 */
:root {
  --base-px: 18;
  @media #{$mq-tablet-and-down} { --base-px: 16; } // Tablet and down
  @media #{$mq-phone-only} { --base-px: 14; } // Phone only

  --grid-cols: 12;
  --grid-gutter: 1.1rem;
  --grid-margin: 3.5rem;
  @media #{$mq-tablet-and-down} { --grid-cols: 8; --grid-margin: 2.25rem; }
  @media #{$mq-phone-only} { --grid-cols: 4; --grid-margin: 1.75rem; }
}

html, body { min-height: 100%; }
html { color: $color-white; font-family: $font-text; background-color: $color-black; }
body {
  -webkit-text-size-adjust: 100%; display: flex; flex-direction: column; min-height: 100vh; opacity: 0; transition: opacity 0.5s $ease-in-quad;
  html.page-loaded & { opacity: 1; }
}
main { display: flex; flex-direction: column; flex: 1; overflow: hidden; }
::selection { color: $color-white; background-color: $color-accent; }


/***
 *    ##     ## ########    ###    ########  ######## ########
 *    ##     ## ##         ## ##   ##     ## ##       ##     ##
 *    ##     ## ##        ##   ##  ##     ## ##       ##     ##
 *    ######### ######   ##     ## ##     ## ######   ########
 *    ##     ## ##       ######### ##     ## ##       ##   ##
 *    ##     ## ##       ##     ## ##     ## ##       ##    ##
 *    ##     ## ######## ##     ## ########  ######## ##     ##
 */
.c-header {
  padding: 2.5rem var(--grid-margin);
  &__logo { width: get-grid-cols(1.8); }
}


/***
 *    ##     ##    ###    #### ##    ##
 *    ###   ###   ## ##    ##  ###   ##
 *    #### ####  ##   ##   ##  ####  ##
 *    ## ### ## ##     ##  ##  ## ## ##
 *    ##     ## #########  ##  ##  ####
 *    ##     ## ##     ##  ##  ##   ###
 *    ##     ## ##     ## #### ##    ##
 */
main {
  --_size: 90rem;
  &::before { content: ""; width: var(--_size); height: var(--_size); position: absolute; top: 0; left: 50%; background: radial-gradient(circle, $color-accent, rgba($color-accent, 0) 70%); transform: translate(-50%, -65%); }
}

/***
 *     #####   ####
 *     #    # #    #
 *     #####  #
 *     #    # #  ###
 *     #####   ####
 */
.c-bg {
  width: 100%; height: 30rem; position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 30%); pointer-events: none;
  &__video { width: 100%; height: 100%; }

  // ================================================================================================================
  // Media Queries
  // ================================================================================================================
  // Tablet and down ------------------------------------------------------------------------------------------------
  @media #{$mq-tablet-and-down} {
    height: 25rem;
  }

  // Phone only -----------------------------------------------------------------------------------------------------
  @media #{$mq-phone-only} {
    width: 160%;
  }
}

/***
 *      ####   ####  #    # ##### ###### #    # #####
 *     #    # #    # ##   #   #   #      ##   #   #
 *     #      #    # # #  #   #   #####  # #  #   #
 *     #      #    # #  # #   #   #      #  # #   #
 *      ####   ####  #    #   #   ###### #    #   #
 */
.c-content {
  width: 100%; padding: 2rem 0 4rem; margin: auto 0;
  &__text {
    display: flex; flex-direction: column; align-items: center; text-align: center;
    h1 { max-width: 72rem; }
    h1 + p { margin-top: 3.5rem; }
    p { max-width: 30rem; opacity: 0.56; margin-top: 1.5rem; }
  }

  // ================================================================================================================
  // Media Queries
  // ================================================================================================================
  // Phone only -----------------------------------------------------------------------------------------------------
  @media #{$mq-phone-only} {
    padding: 3rem 0 0; margin: 0;
  }
}


/***
 *    ########  #######   #######  ######## ######## ########
 *    ##       ##     ## ##     ##    ##    ##       ##     ##
 *    ##       ##     ## ##     ##    ##    ##       ##     ##
 *    ######   ##     ## ##     ##    ##    ######   ########
 *    ##       ##     ## ##     ##    ##    ##       ##   ##
 *    ##       ##     ## ##     ##    ##    ##       ##    ##
 *    ##        #######   #######     ##    ######## ##     ##
 */
.c-footer {
  padding: 3.5rem var(--grid-margin) 2.5rem; overflow: hidden;
  &__infos {
    display: flex; justify-content: space-between; text-align: center;
    a { display: inline-block; font-size: rem(22); color: $color-white; } // margin: 1rem 1.5rem 0;
  }

  // ================================================================================================================
  // Media Queries
  // ================================================================================================================
  // Tablet and down ------------------------------------------------------------------------------------------------
  @media #{$mq-tablet-and-down} {
    &__infos a { font-size: rem(18); }
  }

  // Phone only -----------------------------------------------------------------------------------------------------
  @media #{$mq-phone-only} {
    padding-top: 3rem;
    &__infos {
      flex-direction: column; align-items: center;
      a { margin-top: 1rem; }
    }
  }
}
